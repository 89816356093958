<template>
  <div class="-mt-4">
    <spinner v-if="loading" color="black" />
    <template v-else>
      <entity-card
        :title="entity.name"
        :fields="fields"
        :entity="entity"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              v-t="'register.action.onboard'"
              class="small white mr-2"
              @click="$store.dispatch('modals/openModal', { modal: 'onboardRegister', entity: normalizedEntity })"
            />
            <button
              class="small white mr-2"
              @click="$store.dispatch('modals/openModal', { modal: 'deleteRegister', entity })"
              v-text="$t('general.action.delete')"
            />
            <button
              v-t="'general.action.edit'"
              class="small"
              @click="$store.dispatch('modals/openModal', { modal: 'editRegister', entity: normalizedEntity })"
            />
          </div>
        </template>

        <template #theme>
          <admin-entity-link v-if="entity.theme" :to="entity.theme" class="badge">
            {{ entity.theme?.name }}
          </admin-entity-link>
        </template>

        <template #customerReceiptPrintMode>
          {{ $t('register.customerReceiptPrintMode.' + Object.keys(CUSTOMER_RECEIPT_PRINT_MODE).find(key => CUSTOMER_RECEIPT_PRINT_MODE[key] === entity.customerReceiptPrintMode)) }}
        </template>

        <template #registerLayout>
          <admin-entity-link v-if="entity.selfCheckout && entity.registerLayout" :to="entity.registerLayout" class="badge">
            {{ entity.registerLayout?.name }}
          </admin-entity-link>
          <span v-else>-</span>
        </template>

        <template #sidebarMenu>
          <admin-entity-link v-if="!entity.selfCheckout && entity.sidebarMenu" :to="entity.sidebarMenu" class="badge">
            {{ entity.sidebarMenu?.name }}
          </admin-entity-link>
          <span v-else>-</span>
        </template>

        <template #customerScanners>
          <div v-if="entity.customerScanners.length" class="-mx-8">
            <table>
              <thead>
                <tr>
                  <th v-t="'general.field.name'" scope="col" />
                  <th v-t="'customerIdentifier.type.label'" scope="col" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="scanner in entity.customerScanners" :key="scanner['@id']">
                  <td v-text="scanner.deviceName" />
                  <td v-text="scanner.supportedIdentifierTypes.map(identifier => t(`customerIdentifier.type.${identifier}`)).join(', ')" />
                </tr>
              </tbody>
            </table>
          </div>
          <span v-else v-t="'general.none'" />
        </template>

        <template #printerRules>
          <spinner v-if="loadingRules" color="black" />
          <div v-else-if="!loadingRules && printerRules.length" class="-mx-8">
            <table>
              <thead>
                <tr>
                  <th v-t="'productGroup.label'" scope="col" />
                  <th v-t="'printer.label'" scope="col" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="rule in printerRules"
                  :key="rule['@id']"
                >
                  <td v-text="rule.productGroup?.name" />
                  <td v-text="rule.printer?.name" />
                </tr>
              </tbody>
            </table>
          </div>
          <span v-else v-t="'general.none'" />
        </template>

        <template #shifts>
          <div class="-mx-4">
            <shift-table ref="shiftTable" filter-name="register" :filter-entity="entity" />
          </div>
        </template>

        <template #requestLog>
          <div class="-mx-4">
            <request-log-table ref="shiftTable" filter-name="register" :filter-entity="entity" />
          </div>
        </template>
      </entity-card>

      <edit-register-modal @update="loadData" />
      <delete-modal
        type="deleteRegister"
        entity-translation-key="register"
        label="name"
        @update="$router.push('/registers')"
      />
      <onboard-register-modal />
    </template>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/date-utils';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import AdminEntityLink from '@/components/utils/AdminEntityLink.vue';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditRegisterModal from '@/components/entities/register/EditRegisterModal.vue';
import ShiftTable from '@/components/entities/shift/ShiftTable.vue';
import OnboardRegisterModal from '@/components/entities/register/OnboardRegisterModal.vue';
import RequestLogTable from '@/components/entities/audit/RequestLogTable.vue';
import { CUSTOMER_RECEIPT_PRINT_MODE } from '@/constants';

export default {
  name: 'RegisterDetailPage',
  components: {
    RequestLogTable,
    OnboardRegisterModal,
    AdminEntityLink,
    ShiftTable,
    EditRegisterModal,
    DeleteModal,
    EntityTable,
    EntityCard,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const loading = ref(true);
    const loadingRules = ref(false);
    const entity = ref(null);
    const printerRules = ref([]);
    const normalizedEntity = computed(() => ({
      ...entity.value,
      store: entity.value?.store?.['@id'] ?? null,
      registerLayout: entity.value?.registerLayout?.['@id'] ?? null,
      sidebarMenu: entity.value?.sidebarMenu?.['@id'] ?? null,
      receiptPrinter: entity.value?.receiptPrinter?.['@id'] ?? null,
      preorderPrinter: entity.value?.preorderPrinter?.['@id'] ?? null,
      scale: entity.value?.scale?.['@id'] ?? null,
      paymentTerminal: entity.value?.paymentTerminal?.['@id'] ?? null,
      theme: entity.value?.theme?.['@id'] ?? null,
      customerScanners: entity.value?.customerScanners?.map((scanner) => scanner['@id']) ?? [],
    }));
    const fields = ref([
      { key: 'name', label: t('general.field.name') },
      { key: 'store.name', label: t('store.label') },
      { key: 'lastHeartbeat', label: t('register.lastHeartbeat.label'), formatter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'lastSync', label: t('register.lastSync.label'), formatter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'registerLayout', label: t('registerLayout.label') },
      { key: 'sidebarMenu', label: t('sidebarMenu.label') },
      { key: 'theme', label: t('registerTheme.label') },
      { key: 'selfCheckout', label: t('register.selfCheckout.label') },
      { key: 'customerReceiptPrintMode', label: t('register.customerReceiptPrintMode.label') },
      { key: 'receiptPrinter.name', label: t('printer.receipt.label') },
      { key: 'preorderPrinter.name', label: t('printer.preorder.label') },
      { key: 'scale.name', label: t('scale.label') },
      { key: 'paymentTerminal.name', label: t('paymentTerminal.label') },
      { key: 'invoiceIdentifier', label: t('register.invoiceIdentifier.label') },
      { key: 'language', label: t('general.field.language'), formatter: (v) => t(`general.languages.${v}`) },
      { key: 'orderCalloutNumberRangeStart', label: t('register.orderCalloutNumberRangeStart.label') },
      { key: 'orderCalloutNumberRangeEnd', label: t('register.orderCalloutNumberRangeEnd.label') },
      { key: 'customerScanners', label: t('customerScanner.label', 2), colspan: true },
      { key: 'printerRules', label: t('printerRule.label', 2), colspan: true },
      { key: 'shifts', label: t('shift.label', 2), colspan: true },
      { key: 'requestLog', label: t('requestLog.label', 2), colspan: true },
    ]);

    const loadData = async () => {
      loading.value = true;
      try {
        const { data: register } = await axios.get(`/registers/${route.params.registerId}`);
        entity.value = register;
      } catch (err) {
        console.error(err, err.message, err.response);
      }
      loading.value = false;

      loadingRules.value = true;
      const rulePromises = [
        axios.get('/printer_rules', {
          params: { register: entity.value['@id'] },
        }),
        axios.get('/printer_rules', {
          params: { 'exists[register]': false, store: entity.value.store['@id'] },
        }),
      ];
      Promise.all(rulePromises).then((values) => {
        console.log(values);
        printerRules.value = [];
        values.forEach(({ data: rules }) => {
          if (rules['hydra:member']) {
            printerRules.value.push(...rules['hydra:member']);
          }
        });
      }).catch((err) => {
        console.error(err, err.message, err.response);
      }).finally(() => {
        loadingRules.value = false;
      });
    };

    loadData();

    return {
      CUSTOMER_RECEIPT_PRINT_MODE,
      t,
      loading,
      entity,
      loadingRules,
      printerRules,
      normalizedEntity,
      fields,
      loadData,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep th:first-of-type,
::v-deep td:first-of-type {
  @apply pl-8;
}

::v-deep th:last-of-type,
::v-deep td:last-of-type {
  @apply pr-8;
}
</style>
