<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('register.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editRegister', entity: {
          name: '',
          selfCheckout: false,
          allowPreorder: false,
          store: `/stores/${$route.params.storeId}`,
          receiptPrinter: null,
          preorderPrinter: null,
          sidebarMenu: null,
          scale: null,
          registerLayout: null,
          theme: null,
        } })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteRegister', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />

    <edit-register-modal @update="loadEntities" />
    <delete-modal type="deleteRegister" entity-translation-key="register" label="name" @update="loadEntities" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditRegisterModal from '@/components/entities/register/EditRegisterModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'RegisterIndexPage',
  components: { DeleteModal, EditRegisterModal, EntityTable },
  setup() {
    const route = useRoute();
    const { register: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/registers', {
      sort: 'name',
      defaultParams: {
        store: route.params.storeId,
      },
    });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    };
  },
};
</script>
